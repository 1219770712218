<template lang="pug">
    .pa-10
        PageTitle(:label="id ? `Editar Usuário - #${id}` : 'Novo Usuário'")

        div(v-acl.cant.hide='id ? `user.edit` : `user.add`') Você não possui a permissão necessária para acessar o recurso
        div(v-acl.can.hide='id ? `user.edit` : `user.add`')
            
          v-form(v-model="valid", ref="userform")
              v-row
                  v-col(cols="12", lg="8")
                      v-select(
                      outlined
                      label="Cargo"
                      :items="roles"
                      v-model="user.role_id"
                      required
                      item-text="name"
                      item-value="id"
                      :return-object="false"
                      )
              v-row
                  v-col(cols="12", md="6", lg="4")
                      v-text-field(
                      outlined,
                      v-model="user.name",
                      :rules="rules.name",
                      :counter="100",
                      label="Nome",
                      required
                      )

                  v-col(cols="12", md="6", lg="4")
                      v-text-field(
                      outlined,
                      v-model="user.lastname",
                      :rules="rules.lastname",
                      :counter="100",
                      label="Sobrenome",
                      required
                      )

              v-row
                  v-col(cols="12", md="6", lg="4")
                      v-text-field(
                      outlined,
                      v-model="user.login",
                      :rules="rules.login",
                      :counter="50",
                      label="Login",
                      required
                      )

                  v-col(cols="12", md="6", lg="4")
                      v-text-field(
                      outlined,
                      v-model="user.email",
                      :rules="rules.email",
                      :counter="100",
                      label="E-mail",
                      required
                      )
              
              v-row
                  v-col(cols="12", lg="8")
                      v-text-field(
                      type="password",
                      outlined,
                      v-model="user.password",
                      :rules="rules.password",
                      :counter="100",
                      :label="!id ? 'Senha' : 'Nova Senha'",
                      required
                      )

              v-btn(color="success", x-large, @click="saveUser", :disabled="!valid") Salvar
                  v-icon(right) mdi-content-save
</template>

<script>
export default {
  name: "FormUsers",
  components: {
    PageTitle: () => import("@/components/PageTitle")
  },
  data: function() {
    return {
      valid: false,
      id: null,
      user: {
        id: null,
        login: "",
        name: "",
        lastname: "",
        email: "",
        password: "",
        role_id: null,
      },
      roles: [],
      rules: {
        name: [
          v => !!v || v != "" || "Nome é obrigatório",
          v => v?.length >= 2 || "O Nome de conter mais de 2 caracteres"
        ],
        lastname: [v => !!v || v != "" || "Sobrenome é obrigatório"],
        login: [v => !!v || v != "" || "Login é obrigatório"],
        email: [v => !!v || v != "" || "Email é obrigatório"],
        password: [v => !!v || v != "" || "Senha é obrigatória"]
      } // rules
    };
  },

  mounted: function() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
    }
    this.loadData();
  },

  methods: {
    loadData: async function() {
      if ( this.id && this.acl('user.edit') ) {
        let user = await this.api.get(`user/${this.id}`);
        this.user = user.data;
      }      

      if ( this.acl( (this.id) ? 'user.edit' : 'user.add' ) ) {
        let roles = await this.api.get(`roles`, {all:true});
        this.roles = roles.data;
      }
      
    },

    saveUser: function() {
      let self = this;

      if (this.valid) {
        if (!this.id && this.acl('user.add')) {
          // New
          this.api.post("user", this.user).then(function(res) {
            if (res) {
              self.$root.toast.show({ message: "Usuário criado com sucesso!" });
              self.$refs.userform.reset();
              self.valid = false;
            }
          });
        } else {
          // update
          if (this.acl('user.edit')) {
            this.api.put(`user/${this.id}`, this.user).then(function(res) {
              if (res) {
                self.$root.toast.show({
                  message: "Usuário editado com sucesso!"
                });
              }
            });
          }
        }
      }
    }
  }
};
</script>